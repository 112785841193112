import React from 'react';
import { Card, Divider, Breadcrumb, Layout, Typography, Col, Row, Image, Button } from 'antd';
import { GeneralService } from '../../service';
import AppSppiner from '../app-spinner'
import './app-categories.css';
import Price1 from './prices/price_06.02.2023.docx';
import Price2 from './prices/price_06.05.2024.docx';
import Price3 from './prices/price_12.01.2024.docx';
import { useNavigate } from "react-router-dom";

import noPhoto from './static/noPhoto.png';

function AppProductItem({ id, title, text, file_id }) {

    const nav = useNavigate();

    let img_src = noPhoto;
    if (file_id > 0) {
        img_src = "https://rest.general-trucks.ru/get_image/?file_id=" + file_id;
    }

    return (
        <Col span={8}>
            <Card
                hoverable
                className='product'
                cover={<Image className='product__img' src={img_src} alt={title} />}
            >
                <div>
                    <p>{title}</p>
                    <p>{text}</p>
                </div>
                <Button
                    className='categories__items_button'
                    onClick={() => {
                        nav(`/product/${id}`)
                    }}
                    type='primary'>
                    Подробнее
                </Button>
            </Card>
        </Col>
    );
};

class AppCategoriesSection extends React.Component {
    api = new GeneralService();
    id = 0;
    name = 'noName';
    state = {
        products: [],
        loading: true
    }

    constructor(props) {
        super(props);
        this.id = props.id;
        this.name = props.name;
    }

    componentDidMount() {
        const { id } = this.props;
        this.api.getProducts(id).then((res) => {
            this.setState({
                products: res,
                loading: false
            });
        });
    }

    render() {

        const subcategories = this.state.products
            .map(item => {
                let index = item.title_store.toLowerCase().indexOf('armor');
                if (index >= 0) {
                    item.title_store = item.title_store.slice(index);
                }
                return item.title_store.split(" ")[1];
            })
            .filter(item => item !== undefined)
            .reduce((acc, el) => (acc.some(x => x !== undefined && x.toLowerCase() === el.toLowerCase())) ? acc : [...acc, el], [])
            ;

        const productsBySubcategory = subcategories.map(el => {

            const productsItems = this.state.products.map((item) => {

                let file_id = 0;
                if (item.pictures_ids.length > 0) {
                    file_id = item.pictures_ids[0];
                }

                let indexFindArmor = item.title_store.toLowerCase().indexOf('armor');
                if (indexFindArmor >= 0) {
                    item.title_store = item.title_store.slice(indexFindArmor);
                }

                let descriptionItem = item.desc_store
                    .match(/(?<=это |этот )[А-Яа-яA-Za-z0-9-\s,%(&nbsp;)]+/g);
                if (descriptionItem && descriptionItem.length > 0) {
                    descriptionItem = descriptionItem[0]
                        .replace(/^[а-я]/, u => u.toUpperCase())
                        .replace('&nbsp;', ' ')
                } else {
                    descriptionItem = 'Описание временно отсутствует';
                }

                if (el === item.title_store.split(" ")[1]) {

                    return (
                        <AppProductItem key={item.id} id={item.id} title={item.title_store} text={descriptionItem} file_id={file_id} />
                    );
                }
                return null;
            });

            return (
                <div>
                    <Typography.Title editable level={2}>{el}</Typography.Title>
                    <Row className='categories__items' gutter={[40, 20]}>
                        {productsItems}
                    </Row>
                </div>
            )
        })

        let elements = (<AppSppiner className='loading' />)
        if (!this.state.loading) {
            elements = (
                <Row>
                    {productsBySubcategory}
                </Row>
            );
        }

        return (
            <div className='categories__section content'>
                <Divider className='categories__divider' orientation="left" orientationMargin="0">{this.name}
                </Divider>
                {elements}
            </div>
        );
    }
};

class AppCategoriesAll extends React.Component {
    api = new GeneralService();
    state = {
        categories: []
    }

    componentDidMount() {
        this.api.getAllCategories().then((res) => {
            this.setState({
                categories: res
            });
        });
    }

    render() {

        const categories_item = this.state.categories.map((item) => {

            return (
                <AppCategoriesSection key={item.id} id={item.id} name={item.polnoe_nazvanie} />
            );
        });

        return (
            <>
                <div>
                    {categories_item}
                </div>
            </>
        )
    }
};

function AppCategories() {

    return (
        <>
            <Layout className='categories__header content'>
                <Typography.Title editable level={1}>Каталог
                </Typography.Title>
            </Layout>
            <Breadcrumb
                className='content breadCrumb'
                items={[
                    {
                        title: "Главная",
                        href: '/',
                    },
                    {
                        title: "Каталог",
                    },
                ]}
                separator=">"
            />
            <div   className='content downloadPrice'>
                <a
                    href={Price1}
                    download=""
                    target="_blank"
                    rel="noreferrer"
                >
                    <button>Скачать прайс 06.02.2023.docx</button>
                </a>
                <a
                    href={Price2}
                    download=""
                    target="_blank"
                    rel="noreferrer"
                >
                    <button>Скачать прайс 06.05.2024.docx</button>
                </a>
                <a
                    href={Price3}
                    download=""
                    target="_blank"
                    rel="noreferrer"
                >
                    <button>Скачать прайс 12.01.2024.docx</button>
                </a>
            </div>
            <AppCategoriesAll />

        </>
    )
};

export default AppCategories;