import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "./static/armor-logo.png";
import Tag from "./static/tag.png";
import EMail from "./static/e-mail.png";
import Phone from "./static/smartphone.png";
import YT from "./static/movie.png";
import Vk from "./static/vkontakte.png";
import Inst from "./static/instagram.png";
import './app-footer.css'
import { Col, Row, Typography, List, Space } from 'antd';


const products = [
  {
    title: 'Моторные масла',
  },
  {
    title: 'Трансмиссионные масла',
  },
  {
    title: 'Тормозная жидкость',
  },
  {
    title: 'Антифриз',
  },
];

const contacts = [
  {
    title: 'Россия, Самарская область, г. Тольятти Ставропольский район, М-5 Урал, 980-й километр, 2',
    avatar: Tag,
    fontWeight: 300
  },
  {
    title: 'zakaz@armor-rus.ru',
    avatar: EMail,
    fontWeight: 300
  },
  {
    title: ' 8 800 550 74 51',
    avatar: Phone,
    fontWeight: 700
  },
];


function AppFooter() {
  return (
    <div>
      <Row className='content'>
        <Col span={8} offset={8}>
          <Typography.Text>
            <Link
              style={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#000',
              }}
              to="/categories">
              Продукты
            </Link>
          </Typography.Text>
          <List style={{ paddingTop: '10px' }}
            dataSource={products}
            renderItem={(item) => (
              <List.Item
                style={{
                  border: '0px',
                  padding: '0px'
                }}>
                <List.Item.Meta
                  title={
                    <Link
                      style={{
                        fontSize: '16px',
                        fontWeight: '300',

                      }}
                      to="/categories">{item.title}</Link>}
                />
              </List.Item>
            )}
          />
        </Col>
        <Col span={8}>
          <Typography.Text>
            <Link
              style={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#000',
              }}
              to="/contact">
              Контакты
            </Link>
          </Typography.Text>
          <List style={{ paddingTop: '10px' }}
            dataSource={contacts}
            renderItem={(item) => (
              <List.Item
                style={{
                  border: '0px',
                  padding: '0px'
                }}>
                <List.Item.Meta
                  avatar={<img src={item.avatar} alt="" />}
                  title={<Typography.Title
                    style={{
                      fontSize: '16px',
                      fontWeight: `${item.fontWeight}`,
                    }}>{item.title}</Typography.Title>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row className='content'
        style={{
          paddingTop: '25px'
        }}>
        <Col span={8}>
          <Link to="/">
            <img src={Logo} alt="" className='armor-logo' />
          </Link>
        </Col>
        <Col span={8}>
          <Link style={{
            fontSize: '16px',
            fontWeight: '300',
            color: '#000'
          }} to="/">Политика конфеденциальности</Link>
        </Col>
        <Col span={8}>
          <Space>
            <Link to="https://www.youtube.com/@Armor_lubricants_rus" target='_blank'><img src={YT} alt="" className='icon' /></Link>
            <Link to="https://vk.com/armor_lubricants_rus" target='_blank'><img src={Vk} alt="" className='icon' /></Link>
            <Link to="https://instagram.com/armor_lubricants_rus" target='_blank'><img src={Inst} alt="" className='icon' /></Link>
          </Space>
        </Col>
      </Row>
    </div >
  )
}

export default AppFooter;
