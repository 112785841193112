import { Link } from 'react-router-dom';
import { Row, Col, Space, List, Typography, Layout, Breadcrumb } from 'antd';
import Tag from "../static/tag.png";
import EMail from "../static/e-mail.png";
import Phone from "../static/smartphone.png";
import YT from "../static/movie.png";
import Vk from "../static/vkontakte.png";
import Inst from "../static/instagram.png";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import stock from "../static/stock.webp"
import './contacts-page.css'

const contacts = [
  {
    title: 'Россия, Самарская область, г. Тольятти Ставропольский район, М-5 Урал, 980-й километр, 2',
    avatar: Tag,
    fontWeight: 300
  },
  {
    title: 'zakaz@armor-rus.ru',
    avatar: EMail,
    fontWeight: 300
  },
  {
    title: ' 8 800 550 74 51',
    avatar: Phone,
    fontWeight: 700
  },
  {
    title: ' +7 937 787 89 90',
    avatar: Phone,
    fontWeight: 700
  },
];


const ContactsPage = () => (
  <>
    <Layout className='contacts__header content'>
      <Typography.Title editable level={1}>Контакты
      </Typography.Title>
    </Layout>
    <Breadcrumb
      className='content breadCrumb'
      items={[
        {
          title: "Главная",
          href: '/',
        },
        {
          title: "Контакты",
        },
      ]}
      separator=">"
    />
    <Row className='content'
      justify="space-around" align="top"
      style={{
        paddingBottom: 70
      }}>
      <Col span={12} style={{ marginTop: 40 }}>
        <Row>
          <Col span={20}>
            <List
              dataSource={contacts}
              renderItem={(item) => (
                <List.Item
                  style={{
                    border: '0px',
                    padding: '0px'
                  }}>
                  <List.Item.Meta
                    avatar={<img src={item.avatar} alt="" />}
                    title={<Typography.Title
                      style={{
                        fontSize: '16px',
                        fontWeight: `${item.fontWeight}`,
                      }}>{item.title}</Typography.Title>}
                  />
                </List.Item>
              )} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={24} style={{ marginTop: 30, paddingBottom: '10px', fontSize: 22, fontWeight: "bold" }} >
            Мы в соцсетях
          </Col>
        </Row>
        <Row>
          <Col span={24}
            style={{
              paddingBottom: '20px'
            }}>
            <Space>
              <Link to="/"><img src={YT} alt="" className='icon' /></Link>
              <Link to="/"><img src={Vk} alt="" className='icon' /></Link>
              <Link to="/"><img src={Inst} alt="" className='icon' /></Link>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <img src={stock} style={{ marginTop: 40, weight: 563, height: 216 }} alt="" />
      </Col>
    </Row>
    <Row style={{ marginBottom: 0, marginTop: 0 }}>
      <Col span={24}>
        <YMaps>
          <div>
            <Map
              defaultState={{
                center: [53.513153, 49.585872],
                zoom: 9,
                controls: ["zoomControl", "fullscreenControl"],
              }}
              width="100vw"
              height="550px"
              modules={["control.ZoomControl", "control.FullscreenControl"]}
            >
              <Placemark
                modules={["geoObject.addon.balloon"]}
                defaultGeometry={[53.516382, 49.591962]}
              />
            </Map>
          </div>
        </YMaps>
      </Col>
    </Row>
  </>
)

export default ContactsPage;