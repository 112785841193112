import { Card, Button } from 'antd';
import React, { Component } from 'react';
import AppSpinner from '../app-spinner';
import {GeneralService} from '../../service';
import { Carousel, Col, Row, Image, Breadcrumb, Layout, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './app-product.css';
import noPhoto from './static/noPhoto.png';


const AppProductCarouselItem = ({ id, title }) => {

    let imgPhoto = noPhoto;
    if (id !== noPhoto) {
        imgPhoto = "https://rest.general-trucks.ru/get_image/?file_id=" + id;
    }

    return (
        <div className='carousel-item'>
            <Image src={imgPhoto} alt={title} width={'auto'} />
        </div>
    );
}

function declination(capacity) {
    capacity = Math.abs(capacity) % 100;
    const capacity2 = capacity % 10;
    if (capacity2 === 1) return 'литр';
    if (capacity2 > 1 && capacity2 < 5) return 'литра';
    if ((capacity >= 5 && capacity <= 20) || capacity === 0) return 'литров';
}

function AppProductsVersion({productVariants, product}) {
    const nav = useNavigate();

    const volume = productVariants.map((item) => {

        const capacity = item.title_store.match(/(?<=(, | )+)[0-9]+( *)(?=(л))/g);

        let img_src = noPhoto;
        const pict = item.pictures_ids[0];
        if (pict > 0) {
            img_src = "https://rest.general-trucks.ru/get_image/?file_id=" + pict;
        }

        return (
            <Col 
                key = { item.id }
                style={{ 
                    width: '20%', 
                    cursor: 'pointer',
                    ...(item.id === product.id
                        ? { opacity: '1' }
                        : { opacity: '0.4' })
                }}
                onClick = {() => { nav(`../product/${item.id}`) }}
            >
                <Card 
                    cover={<Image src={img_src} alt={capacity} preview= {false}/>} 
                >
                    <p>{capacity} {declination(capacity)}</p>
                </Card>

            </Col>
        )
    })

    return (
        <Col span={24} className='product__variants'>
            {volume}
        </Col>
    )
        
}

function AppProductOther({ id, title, text, file_id, price }) {

    // const nav = useNavigate();

    let img_src = noPhoto;
    if (file_id > 0) {
        img_src = "https://rest.general-trucks.ru/get_image/?file_id=" + file_id;
    }

    return (
        <Col span={8}>
            <Card
                hoverable
                cover={<Image src={img_src} alt={title} />}
            >
                <div>
                    <p>{title}</p>
                    <p>{text}</p>
                </div>
                {/* <p>Цена: {price} руб.</p> */}
                <Button 
                    disabled
                    className='product__other_button' 
                    // onClick={() => { 
                    //     nav(`/product/${id}`) 
                    // }} 
                    type='primary'>
                    В корзину
                </Button>
            </Card>
        </Col>
    );
};

class AppProduct extends Component {
    api = new GeneralService();

    state = {
        product: null,
        productName: '',
        productPrice: 0,
        productDescriptionMini: '',
        productDescription: '',
        productPictures: [],
        productVariants: [],
        productOther: [],
        loading: true
    }

    async componentDidMount() {
        const { id } = this.props;
        const productResponse = await this.api.getProduct(id);

        if (productResponse.length > 0) {
            const product = productResponse[0];

            const name = product.title_store.match(/(?=ARMOR |Armor )[А-Яа-яA-Za-z0-9-\s%(&nbsp;)\+]+/g)[0];

            let description = product.desc_store
                    .match(/(?<=это |этот )[А-Яа-яA-Za-z0-9-\s,%(&nbsp;)]+/g);
            if (description && description.length > 0 ) {
                description = description[0]
                    .replace(/^[а-я]/, u => u.toUpperCase())
                    .replace('&nbsp;', ' ')
            } else {
                description = 'Описание временно отсутствует';
            }

            const productCategoryId =  JSON.parse(product.gruppi_json_store).pop();

            const productVariantsResponse = await this.api.getProducts(productCategoryId);
            const productVariants = productVariantsResponse
                .filter((item) => {
                    let nameProductVersions = item.title_store.match(/(?=ARMOR |Armor )[А-Яа-яA-Za-z0-9-\s%(&nbsp;)\+]+/g);
                    if (nameProductVersions && nameProductVersions.length > 0  &&nameProductVersions[0] === name) return item;
                })
                .sort( function (a, b) {
                    const aLitr = a.title_store.match(/(?<=(, | )+)[0-9]+( *)(?=(л))/g);
                    const bLitr = b.title_store.match(/(?<=(, | )+)[0-9]+( *)(?=(л))/g);
                    return aLitr - bLitr;
                });

            let sliders = <AppProductCarouselItem key={noPhoto} title={name} id={noPhoto} />;
            if (product.pictures_ids.length > 0) {
                sliders = product.pictures_ids.map((picture) => {
                    return <AppProductCarouselItem key={picture} title={name} id={picture} />
                });
            }

            const productOther = [];
            const lubricant = await this.api.getProducts(120);
            const antifreeze = await this.api.getProducts(105);
            const tormoz = await this.api.getProducts(118);
            const oil = await this.api.getProducts(102);
            switch (productCategoryId) {
                case 120:
                    console.log('lubricant');
                    productOther.push(oil[0]);
                    productOther.push(antifreeze[0]);
                    productOther.push(tormoz[0]);
                    break;
                case 105:
                    console.log('antifreeze');
                    productOther.push(oil[1]);
                    productOther.push(lubricant[0]);
                    productOther.push(tormoz[0]);
                    break;
                case 118:
                    console.log('tormoz');
                    productOther.push(oil[0]);
                    productOther.push(lubricant[0]);
                    productOther.push(antifreeze[0]);
                    break;
                default:
                    console.log('oil');
                    productOther.push(lubricant[0]);
                    productOther.push(antifreeze[0]);
                    productOther.push(tormoz[0]);
                    break;
            }
            console.log(productOther);

            this.setState({
                product: product,
                productName: name,
                productPrice: product.rekomend_cena_za_ed,
                productDescriptionMini: description,
                productDescription: product.desc_store,
                productPictures: sliders,
                productVariants: productVariants,
                productOther: productOther,
                loading: false,
            });
        }
    }

    render() {

        let elements = (<AppSpinner className='loading' />)
        if (!this.state.loading) {

            const productOtherRender = this.state.productOther.map((item) => {

                let file_id = 0;
                if (item.pictures_ids.length > 0) {
                    file_id = item.pictures_ids[0];
                }

                const nameProductOther = item.title_store.match(/(?=ARMOR |Armor )[А-Яа-яA-Za-z0-9-\s%(&nbsp;)&\+]+/g);

                let descriptionProductOther = item.desc_store
                    .match(/(?<=это |этот )[А-Яа-яA-Za-z0-9-\s,%(&nbsp;)]+/g);
                if (descriptionProductOther && descriptionProductOther.length > 0 ) {
                    descriptionProductOther = descriptionProductOther[0]
                        .replace(/^[а-я]/, u => u.toUpperCase())
                        .replace('&nbsp;', ' ')
                } else {
                    descriptionProductOther = 'Описание временно отсутствует';
                }
  
                return (
                    <AppProductOther key={item.id} id={item.id} title={nameProductOther} text={descriptionProductOther} file_id={file_id} price={item.rekomend_cena_za_ed}/>
                );
                
            });
            
            elements = (
                <>
                    <Layout className='product__header content'>
                        <Typography.Title editable level={1}>Товар
                        </Typography.Title>
                    </Layout>
                    <Breadcrumb
                        className='content breadCrumb'
                        items={[
                            {
                                title: <Link to="/">Главная</Link>,
                            },
                            {
                                title: <Link to="/categories">Каталог</Link>,
                            },
                            {
                                title: this.state.productName,
                            },
                        ]}
                        separator=">"
                    />
                    <Row gutter={[40, 30]} className='product__info content'>
                        <Col span={14}>
                            <Row>
                                <Col span={24}>
                                    <Carousel className='product__photo' autoplay>
                                    {this.state.productPictures}
                                </Carousel>
                                </Col>
                                <AppProductsVersion key={this.state.product.id} productVariants={this.state.productVariants} product={this.state.product} />
                            </Row>
                            
                        </Col>
                        <Col span={10}>
                            <Card className='brief__info'>
                                <p>{this.state.productName}</p>
                                <p>{this.state.productDescriptionMini}</p>
                                {/* <p>Цена: {this.state.productPrice} руб.</p> */}
                                <Button disabled><span>В КОРЗИНУ</span></Button>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card className='description'>
                                <div dangerouslySetInnerHTML={{ __html: this.state.productDescription }}></div>
                                <Button disabled><span>Добавить в корзину</span></Button>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Typography.Title editable level={2}>С этим товаром также берут</Typography.Title>
                            <Row className='product__other' gutter={[40, 20]}>
                                {productOtherRender}
                            </Row>
                        </Col>
                    </Row>
                </>
            );
        };
        return elements;
    };
}

export default AppProduct