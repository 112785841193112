import { Card, Col, Row, Button, Image, Typography, Carousel } from 'antd';
import './app-main-info.css';
import pict1 from './static/image-035.jpg';
import pict2 from './static/image-446.jpg';
import pict3 from './static/image-810.jpg';
import pict4 from './static/Group-103.jpg';
import pict5 from './static/Group-104.jpg';
import pict6 from './static/product-01.png';
import pict7 from './static/product-02.png';
import pict8 from './static/product-03.png';
import pict9 from './static/advantage-01.svg';
import pict10 from './static/advantage-02.svg';
import pict11 from './static/advantage-03.svg';
import { Link } from 'react-router-dom';


import gallery1 from './static/gallery-01.png';
import gallery2 from './static/gallery-02.png';
import gallery3 from './static/gallery-03.png';
import gallery4 from './static/gallery-04.png';
import gallery5 from './static/gallery-01.png';
import gallery6 from './static/gallery-02.png';

//#region h2Texts
const h2Texts = [
    { name: 'h2-1', text: 'Популярные товары' },
    { name: 'h2-2', text: 'Ведущий производитель и поставщик смазочных материалов, пластичных смазок и присадок для ухода за автомобилем в ОАЭ' },
    { name: 'h2-3', text: 'Смазочные материалы премиум-качества с ОАЭ с защитой и производительностью' }
]
//#endregion

//#region AppMainMenu
const items = [
    {
        content: 'Для легковых авто',
        image: pict1,
    },
    {
        content: 'Для грузовых авто',
        image: pict2,
    },
    {
        content: 'Трансмиссия',
        image: pict3,
    }
]

function AppMainMenuItem({ content, image }) {
    return (
        <div class='main__item'>
            <img src={image} alt='main__img' />
            <div>
                <p>{content}</p>
            </div>
        </div>
    )
};

function AppMainMenu() {

    const itemsMenu = items.map((item) => {
        return <AppMainMenuItem {...item} />
    });

    return (
        <div className='main-menu content'>
            {itemsMenu}
        </div>
    );
};
//#endregion

//#region AppMainPopularGoods
const popularGoods = [
    {
        name: 'Armor Extreme+ 10W-40 4л.',
        text: 'Синтетическое моторное масло API SN',
        pict: pict6,
        id: '1064025'
    },
    {
        name: 'Armor Extreme++ 5W-30, 4л.',
        text: 'Полностью синтетическое моторное масло API SN',
        pict: pict7,
        id: '1064123'
    },
    {
        name: 'Armor Extreme++ 5W-40, 4л.',
        text: 'Полностью синтетическое моторное масло API SN',
        pict: pict8,
        id: '1064632'
    }
]

function AppMainGood({ name, text, pict, id }) {

    return (
        <Col span={8}>
            <Card
                cover={<Image src={pict} alt={name} />}
            >
                <p>{name}</p>
                <p>{text}</p>
                <Button className='popular__goods_button' type='primary'><Link to={`/product/${id}`}>Подробнее </Link></Button>
            </Card>
        </Col >
    );
};

function AppMainPopularGoods() {

    const goods = popularGoods.map((item) => {
        return <AppMainGood {...item} />
    });

    return (
        <div className='popular__goods content'>
            {goods}
        </div>
    );

};
//#endregion

//#region AppMainInfoLubricants
const textLubricants = [
    'Armor Lubricants RUS — официальный дистрибьютор производителя смазочных материалов «Armor Lubricants».',
    'Armor Lubricants - ведущий производитель смазочных материалов в ОАЭ.\n Благодаря стремлению к совершенству и широкому ассортименту высококачественных смазочных материалов, готовы удовлетворить все ваши потребности в смазочных материалах. Команда “Armor” усердно работает над повышением качества смазочных материалов, чтобы поставлять лучшую продукцию, необходимую клиентам.',
    'Миссия и руководящий принцип всегда заключаются в удовлетворении потребностей клиентов, сохраняя при этом высокий уровень качества продукции.'
]

function AppMainInfoLubricants() {

    const lubricants = textLubricants.map((item) => {
        return <p>{item}</p>
    });

    return (
        <div className='main__info-lubricants content'>
            {lubricants}
        </div>
    );
};
//#endregion

//#region AppMainAdvantages
const advantages = [
    {
        title: 'Мы превосходим качество',
        text: [
            'Передовые формулы для продуктов премиум-класса',
            'Увеличение срока службы оборудования за счет базовых масел самого высокого качества и самых современных присадок.',
            'Сертифицированные и одобренные на международном уровне смазочные материалы.'
        ],
        pict: pict9
    },
    {
        title: 'Новейшие технологии',
        text: [
            'Наши инженеры производят смазочные масла, которые сохраняют свою вязкость, уменьшают износ двигателя и способствуют обеспечению надежности.',
            'Поставляем нашим клиентам лучшие смазочные материалы.',
            'Придерживаемся мировых модных формул.',
            'Используем современные добавки и сырье.'
        ],
        pict: pict10
    },
    {
        title: 'Гарантированная производительность',
        text: [
            'Общая производительность улучшилась благодаря использованию наших высокопроизводительных решений.',
            'Плавная смазка и гарантированная производительность.',
            'Циклы слива могут быть увеличены, а количество отложений уменьшено.'
        ],
        pict: pict11
    }
]

function AppMainAdv({ title, text, pict }) {

    const mas = text.map((item) => {
        return <p>{item}</p>
    });

    return (
        <Col span={8}>
            <Card>
                <Image src={pict} alt={title} preview={false} />
                <p className='title__avantages'>{title}</p>
                {mas}
            </Card>
        </Col>
    );
};

function AppMainAdvantages() {

    const adv = advantages.map((item) => {
        return <AppMainAdv {...item} />
    });

    return (
        <div className='main__avantages content'>
            {adv}
        </div>
    );
};
//#endregion

//#region AppMainGallery
function AppMainGallery() {
    const imagesGallery = [
        gallery1, gallery2, gallery3, gallery4, gallery5, gallery6
    ];

    const handleWheelScroll = (e) => {
        if (e.deltaY < 0) {
            carousel.prev();
        } else {
            carousel.next();
        }
    };

    let carousel = null;

    return (
        <div className="horizontal-gallery" onWheel={handleWheelScroll}>
            <Carousel ref={(el) => (carousel = el)} dots={false} arrows={false}>
                {imagesGallery.map((image, index) => (
                    <div key={index}>
                        <img alt={`img ${index + 1}`} src={image} />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
//#endregion

function AppMainInfo() {

    return (
        <div className='main'>
            <Row className='buttons-connection content'>
                <Col
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    span={12}>
                    <Button><a href='tel:88005507451'>Связаться с отделом продаж</a></Button>
                </Col>
                <Col style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                    span={12}>
                    <Button><a href="#feedback1">Запросить обратный звонок</a></Button>
                </Col>
            </Row>
            <Row style={{ margin: 0 }}>
                <AppMainMenu />
            </Row>
            <Row>
                <Typography.Title className='main__h2 content' editable level={2} style={{ margin: 0 }}>
                    {h2Texts.find(item => item.name === 'h2-1').text}
                </Typography.Title>
            </Row>
            <Row gutter={40} style={{ margin: 0 }}>
                <AppMainPopularGoods />
            </Row>
            <Row className='button-all-goods content'>
                <Col span={24} style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Button><Link to="/categories">Посмотреть весь каталог</Link></Button>
                </Col>
            </Row>
            <Row>
                <Typography.Title className='main__h2 content' editable level={2} style={{ margin: 0 }}>
                    {h2Texts.find(item => item.name === 'h2-2').text}
                </Typography.Title>
                <AppMainInfoLubricants />
                <Image preview={false} className='main__pict content' src={pict4} />
            </Row>
            <Row>
                <Typography.Title className='main__h2 content' editable level={2} style={{ margin: 0 }}>
                    {h2Texts.find(item => item.name === 'h2-3').text}
                </Typography.Title>
                <Image preview={false} className='main__pict content' src={pict5} />
            </Row>
            <Row gutter={40} style={{ margin: 0 }}>
                <AppMainAdvantages />
            </Row>
            <Row>
                <AppMainGallery />
            </Row>

        </div>
    );
};

export default AppMainInfo;