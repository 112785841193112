import React from 'react';
import Logo from "./static/armor-logo.png";
import { Link } from 'react-router-dom';
import './app-header.css';
import { Row, Col, Menu, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

function AppHeader() {


  const navigate = useNavigate();

  const onMenuClick = (item) => {
    navigate(`/${item.key}`)
  };

  return (
    <Row className='content'>
      <Col className='logo' span={4}
        style={{
          display: 'flex',
          alignItems: 'flex-end'
        }}>
        <Link to="/">
          <img src={Logo} alt="" className='armor-logo' />
        </Link>
      </Col>
      <Col span={12}
        style={{
          display: 'flex',
          alignItems: 'flex-end'
        }}>
        <Menu
          style={{
            fontSize: "16px",
            fontWeight: "light",
            flex: "auto",
            justifyContent: 'center',
            minWidth: 0,
            border: '#000',
          }}
          onClick={onMenuClick}
          mode='horizontal'
          items={[
            {
              label: "Каталог",
              key: "categories"
            },
            {
              label: "Сертификаты",
              key: "certificates"
            },
            // {
            //   label: "О нас",
            //   key: "aboutus"
            // },
            {
              label: "Контакты",
              key: "contacts"
            },
          ]}>
        </Menu>
      </Col>
      <Col span={8} style={{
        display: 'flex',
        alignItems: 'flex-end'
      }}>
        <Typography.Title className='call-now'>
          <a href='tel:88005507451'>Позвоните сейчас 8 800 550 74 51</a></Typography.Title>
      </Col>
    </Row>
  )
};
export default AppHeader;


