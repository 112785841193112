import { Layout, Space } from 'antd';
import AppHeader from '../app-header';
import AppFooter from '../app-footer';
import Feedback from '../feedback/feedback';
import {
    MainPage,
    ProductPage,
    ProductsPage,
    CertificatesPage,
    ContactsPage,
} from '../pages'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './app.css';

const { Header, Footer, Content } = Layout;

const App = () => (
    <Router>
        <Space
            direction="vertical"
            style={{
                width: '100%',
            }}
            size={[0, 48]}
        >
            <Layout>
                <Header
                    style={{
                        backgroundColor: 'white',
                        height: '114px',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 0
                    }}>
                    <AppHeader /></Header>
                <Content>
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/categories/" exact element={<ProductsPage />} />
                        <Route path="/categories/:id" element={<ProductsPage />} />
                        <Route path="/product/:id" element={<ProductPage />} />
                        <Route path="/certificates" element={<CertificatesPage />} />
                        <Route path="/contacts" element={<ContactsPage />} />
                    </Routes>
                </Content>
                <Content>
                    <div id={"feedback1"}>
                        <Feedback />
                    </div>
                </Content>
                <Footer style={{ backgroundColor: 'white' }}><AppFooter /></Footer>
            </Layout>
        </Space>
    </Router >
);
export default App;