import { Row, Col, Card, List, Typography, Image, Breadcrumb } from 'antd';
import Certif1 from "../static/sert1.webp";
import Certif2 from "../static/sert2.webp";
import Certif3 from "../static/sert3.webp";
import Certif4 from "../static/sert4.webp";
import Certif5 from "../static/sert5.webp";
import './certificates.css'

const certificates = [
  {
    content: Certif1,
    className: 'certif__01'
  },
  {
    content: Certif2,
    className: 'certif__02'
  },
  {
    content: Certif3,
    className: 'certif__03'
  },
  {
    content: Certif4,
    className: 'certif__04'
  },
  {
    content: Certif5,
    className: 'certif__05'
  },
];

const CertificatesPage = () => (
  <>
    <Row className='certificates-header content'>
      <Col span={24} >
        <Typography.Text style={{
          fontSize: 35,
          fontWeight: 500,
          color: '#ffff'
        }}>Сертификаты</Typography.Text>
      </Col>
    </Row>
    <Breadcrumb
      className='content breadCrumb'
      items={[
        {
          title: "Главная",
          href: '/',
        },
        {
          title: "Сертификаты",
        },
      ]}
      separator=">"
    />
    <Row className='content'>
      <Col span={24}>
        <List
          className='sertificates'
          grid={{
            gutter: [16, 145],
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
          }}
          dataSource={certificates}
          renderItem={(item) => (
            <List.Item>
              <Card className={`${item.className} sertificate__card`}>
                <Image width={320} src={item.content} />
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  </>
)

export default CertificatesPage;