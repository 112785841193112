import React, { Component } from 'react';
import { Button, Form, Input, Row, Col, Typography } from 'antd';
import InputMask from 'react-input-mask';
import { ArmorService } from '../../service';
import './feedback.css'

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: 'Требуется ввести ${label}!',
    types: {
        phone: '${label} введен некорректно!',
    }
};
/* eslint-enable no-template-curly-in-string */


const PhoneInput = ({ onChange, val }) => {

    return (
        <InputMask
            mask="+7 (999) 999-99-99"
            maskChar="_"
            onChange={onChange}
            value={val}
        >
            {() => <Input placeholder="+7 (900) 000-00-00" />}
        </InputMask>
    );
};

const NameUserInput = ({ onChange, val }) => {
    return (
        <Input
            placeholder="Имя"
            value={val}
            onChange={onChange} />
    );
};

class Feedback extends Component {
    api = new ArmorService();
    constructor(props) {
        super(props);
        this.state = {
            nameUser: '',
            phone: ''
        };
    }
    onFinish = (value) => {
        this.api.sendRequest({
            "phone": this.state.phone,
            "name": this.state.nameUser,
            "status": "NEW"
        })
        this.setState({
            nameUser: '',
            phone: ''
        });
    };

    handleNameUserChange = (event) => {
        console.log('handleNameUserChange', event.target.value);
        this.setState({ nameUser: event.target.value });
    }

    handlePhoneChange = (event) => {
        console.log('handlePhoneChange', event.target.value);
        this.setState({ phone: event.target.value });
    }

    render() {
        return (
            <Row className='feedback content' id='feedback'
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Col span={12} className='feedback__text'>
                    <Row>
                        <Col>
                            <Typography.Text style={{
                                fontWeight: '700'
                            }}>
                                Стать партнером с лучшим производителем и поставщиком смазочных материалов из ОАЭ
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography.Text style={{
                                fontWeight: '300'
                            }}>
                                Оставьте заявку заполнив только своё Имя и номер телефона
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                    <Form
                        name="nest-messages"
                        className='feedback__form'
                        onFinish={this.onFinish}
                        validateMessages={validateMessages}
                    >
                        <Form.Item
                            name={['user', 'nameUser']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <NameUserInput onChange={this.handleNameUserChange} val={this.state.nameUser} />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'phone']}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <PhoneInput onChange={this.handlePhoneChange} val={this.state.phone} />
                        </Form.Item>
                        <Form.Item>
                            <Button className='submit-button' type="primary" htmlType="submit">
                                ОТПРАВИТЬ
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        )
    }
}
export default Feedback;