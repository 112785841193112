import React from 'react';
import AppCategories from '../app-categories';
import AppProducts from '../app-products';
import {
  useParams,
} from "react-router-dom";


const ProductsPage = () => {
  const { id } = useParams()
  let element = <AppCategories />
  if (id) {
    element = <AppProducts categoryID={id} />
  }
  return (
    <>
      {element}
    </>
  )
}


export default ProductsPage;