import { Carousel, Row, Col, Space, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import img1 from './static/armor-lubricants.png';
// import img2 from './static/10-year-anniversary-of-armor-lubricants-company-in-uae.png';
// import img3 from './static/armor_banner_carcare.png';
// import img4 from './static/armor_banner_industrail-2.png';
import './app-carousel.css';

const items = [
    {
        key: '1',
        title: 'Премиальные масла в России от лидера рынка в ОАЭ',
        content: 'Стать партнером',
        image: img1
    },
    // {
    //     key: '1',
    //     title: '10 лет достижений',
    //     content: 'Armor Lubricants начала свой путь в 2012 году, став сектором смазочных материалов Ближнего Востока с его быстрым ростом. Мы стремимся предоставить нашим клиентам качественный, надежный и эффективный сервис. Обладая специальными знаниями, мы предлагаем широкий ассортимент смазочных материалов, изготовленных по индивидуальному заказу.',
    //     image: img1
    // },
    // {
    //     key: '2',
    //     title: 'Мощные промышленные, гидравлические и морские смазочные материалы',
    //     content: 'Специально разработанные гидравлические, индустриальные и специальные масла. Превосходная производительность и максимальная защита гарантируются нашим специальным ассортиментом морских и промышленных смазочных материалов.',
    //     image: img2
    // },
    // {
    //     key: '3',
    //     title: 'Средства по уходу за автомобилем премиум-класса',
    //     content: 'Профессиональные решения для современных автомобилей, сделанные с использованием передовых немецких технологий. Специально разработанные продукты по уходу за автомобилем для каждой части вашего автомобиля.',
    //     image: img3
    // },
    // {
    //     key: '4',
    //     title: 'Испытайте лучшее моторное масло для лучшей работы двигателя',
    //     content: 'Что касается автомобильных смазочных материалов, Armor Lubricants считается лучшим производителем смазочных материалов в ОАЭ.',
    //     image: img4
    // }
]

function AppCarouselItem({ title, content, image }) {
    return (
        <div className='carousel-item'>
            <Row className='content'>
                <Col span={12}>
                    <Space direction="vertical">
                        <Typography.Text style={{
                            fontSize: '36px',
                            fontWeight: 'bold',
                            color: '#ffff'
                        }}>{title}</Typography.Text>
                        <Typography.Text style={{
                            fontSize: '36px',
                            fontWeight: 'medium',
                            color: '#ffff'
                        }}>{content}</Typography.Text>
                        <Button style={{
                            width: 560,
                            height: 60,
                            fontSize: '22px',
                            fontWeight: 'medium',
                            opacity: '0.77'
                        }}><a href="#feedback1">ОТПРАВИТЬ ЗАЯВКУ</a></Button>
                    </Space>
                </Col>
                <Col span={12}>
                    <img src={image} alt={title} className="baner_Arm_1d8i3 wow fadeInUp" />
                </Col>
            </Row>
        </div >
    )

};
function AppCarousel() {

    const sliders = items.map((item) => {

        const { key, ...itemProps } = item;
        return <AppCarouselItem key={key} {...itemProps} />
    });

    return (
        <div className='main-carousel'>
            <Carousel autoplay>
                {sliders}
            </Carousel>
        </div>
    );
};
export default AppCarousel;