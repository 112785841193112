import React from 'react';
import { Card, Space, Divider, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import AppSppiner from '../app-spinner'
import {GeneralService} from '../../service';
import './app-products.css';
import {
    useNavigate,
} from "react-router-dom";

function AppProductItem({ id, title, file_id }) {
    const { Meta } = Card;
    let img_src = '/0.jpeg';
    if (file_id > 0) {
        img_src = "https://rest.general-trucks.ru/get_image/?file_id=" + file_id;
    }
    const nav = useNavigate();
    return (
        <Card
            hoverable
            onClick={() => { nav(`/product/${id}`) }}
            style={{ width: 240, height: 290, textAlign: "center" }}
            cover={<img style={{ width: "auto", height: 200 }} alt="example" src={img_src} />}
        >
            <Meta title="" description={title} className="color-black" />
        </Card>
    )

};

class AppProducts extends React.Component {
    api = new GeneralService();
    state = {
        products: [],
        loading: true
    }
    componentDidMount() {
        const { categoryID } = this.props;
        this.api.getProducts(categoryID).then((res) => {
            this.setState({
                products: res,
                loading: false
            });
        });
    }

    render() {
        const { products, loading } = this.state;
        let elements = (<AppSppiner />)
        if (!loading) {
            const choose_category = products[0].gruppi_polnoe_nazvanie_store.split(':').pop();
            const product_items = products.map((item) => {
                const { id, title_store, pictures_ids } = item;
                let file_id = 0;
                if (pictures_ids.length > 0) {
                    file_id = pictures_ids[0]
                }
                return (
                    <AppProductItem key={id} id={id} title={title_store} file_id={file_id} />
                );
            });
            elements = (
                <>
                    <Divider orientation="left">
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/categories">Продукция</Link>,
                                },
                                {
                                    title: choose_category,
                                },
                            ]}
                        />
                    </Divider>
                    <div className="site-layout-content-center">
                        <Space wrap size={[10, 10]} style={{ width: 1240 }} >
                            {product_items}
                        </Space>
                    </div>
                </>
            );
        };
        return elements
    }
}
export default AppProducts;